import ElementWidthControl from "shared/interfaces/elementWidthControl";
import { TxtColor, applicationPalettes, tabletBreakpoint, whiteColor } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useNoteStyles = makeSharedStyles(theme => ({
    root: {
        boxSizing: "border-box",
        padding: theme.spacing(1.5, 2.25),
        borderRadius: ".5rem",
        width: "100%",
        maxWidth: "100%",
        minWidth: "100%",
        flexShrink: 0,
        "&.info": {
            background: applicationPalettes.primary[1],
        },
        "&.error": {
            backgroundColor: applicationPalettes.red[1],
        },
        "&.transparent": {
            backgroundColor: "transparent",
            padding: 0,
        },
        "&.critical": {
            backgroundColor: applicationPalettes.red[500],
            borderRadius: 0,
            padding: theme.spacing(1, 2),
            alignItems: "center",
            "& *": {
                color: whiteColor,
            },
            "& svg": {
                marginTop: 0,
            },
        },
        "&.warning": {
            backgroundColor: applicationPalettes.red[1],
        },
        "&.success": {
            background: applicationPalettes.green[1],
        },
        [theme.breakpoints.up(tabletBreakpoint)]: {
            width: (props: ElementWidthControl) => props.width,
            maxWidth: (props: ElementWidthControl) => props.maxWidth,
            minWidth: (props: ElementWidthControl) => props.minWidth,
        },
    },
    container: {
        display: "flex",
    },
}));

export const useNoteIconStyles = makeSharedStyles(theme => ({
    root: {
        marginTop: theme.spacing(0.25),
        marginRight: theme.spacing(1.5),
        fontSize: "20px",
        "&.info": {
            color: applicationPalettes.primary[500],
        },
        "&.transparent": {
            color: applicationPalettes.primary[500],
            marginTop: 0,
            fontSize: "24px",
        },
        "&.error": {
            color: applicationPalettes.red[500],
        },
        "&.warning": {
            color: applicationPalettes.orange[500],
        },
        "&.success": {
            color: applicationPalettes.green[500],
        },
    },
}));

export const useContentStyles = makeSharedStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    text: {
        color: TxtColor.Txt2,
    },
}));

export const useMultiNoteStyles = makeSharedStyles(theme => ({
    root: {
        display: "flex",
    },
    noteWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },

    note: {
        display: "flex",
        flexDirection: "column",
    },
    text: {
        color: TxtColor.Txt1,
    },
}));
