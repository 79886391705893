/**
 *
 *
 * Tags: Accounts
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_ADMIN_ACCOUNT_URL = (accountId: number): string => `api/v1/admin/accounts/${accountId}`;

export const apiGetAdminAccount = async (
    accountId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetAdminAccountResponse> => {
    const result = await httpGet<ApiGetAdminAccountResponse>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_GET_ADMIN_ACCOUNT_URL(accountId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetAdminAccountResponse = GetAccountResponse;

interface GetAccountResponse {
    accountTypeId: 1 | 2 | 3 | 4 | 5 | 100;
    primaryUserId: number;
}
