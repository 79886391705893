import find from "lodash/find";
import { generatePath, matchPath } from "react-router";

import { getUrlWithQueryParams } from "shared/api/utils";
import hasPermissions from "shared/helpers/hasPermissions";
import { RolePermission } from "shared/interfaces/rolePermission";
import routeNames from "shared/routes/constants/routeNames";
import RouteValue, { RouteComponent } from "shared/routes/models/routesValue";
import routes from "shared/routes/routes";

import { RootStore } from "app/rootStore";

export function findRouteByName(location: string): RouteValue | undefined {
    return find(
        routes,
        route =>
            !!matchPath(location, {
                path: route.name,
                exact: route.exact,
                strict: false,
            })
    );
}

export function isRoutePermitted(
    { permission, isTolerantPermissions, customPermission }: RouteComponent,
    userPermissions: Set<RolePermission>
): boolean {
    return customPermission
        ? customPermission()
        : hasPermissions(permission || [], userPermissions, isTolerantPermissions);
}

export function navigateOnSuccessLogin(rootStore: RootStore): void {
    rootStore.routerStore.push(rootStore.userHomeRoute);
}

export function navigateToUrl(rootStore: RootStore, url: string): void {
    rootStore.routerStore.push(url);
}

export function navigateToLoginPage(rootStore: RootStore): void {
    rootStore.routerStore.push(routeNames.LOGIN.ROOT);
}

export function currentUserLinkGuard(currentUserId: number, targetUserId: number, returnUrl: string): string {
    if (currentUserId === targetUserId) {
        return routeNames.MY_PROFILE.ROOT;
    }

    return returnUrl;
}

export function generateAdminCompanyProfileLink(companyId: number): string {
    return generatePath(routeNames.ADMIN.BUSINESSES.PROFILE.ROOT, { id: companyId });
}

export function generateCompanyProfileLink(): string {
    return generatePath(routeNames.COMPANY.ROOT);
}

export function generateUserProfileLink(userId: number, accountId?: number): string {
    const path = generatePath(routeNames.ADMIN.USER.DETAILS, {
        id: userId,
    });

    return accountId ? getUrlWithQueryParams(path, { accountId }) : path;
}

export function generateAdminUserProfileLink(userId: number): string {
    return generatePath(routeNames.ADMIN.SETTINGS.ADMIN.ROOT, {
        id: userId,
    });
}

export function generateAccountLink(accountId: number): string {
    return generatePath(routeNames.REDIRECTS.ACCOUNT.ROOT, { id: accountId });
}

export function generateStaffUserProfileLink(userId: number): string {
    return generatePath(routeNames.STAFF.USER, { id: userId });
}

export function generateOfficeLink(officeId: number, accountId?: number | null): string {
    const path = generatePath(routeNames.COMPANY.OFFICE.REDIRECT, { id: officeId });
    return accountId ? getUrlWithQueryParams(path, { accountId }) : path;
}

export function generateReleaseRequestLink(releaseRequestId: number): string {
    return generatePath(routeNames.REDIRECTS.RELEASE_REQUEST.ROOT, { id: releaseRequestId });
}
