/**
 * Summary: Get account unallocated funds.
 *
 * Tags: UnallocatedFunds
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_ADMIN_ACCOUNT_UNALLOCATED_FUNDS_URL = (accountId: number): string =>
    `api/v1/admin/accounts/${accountId}/unallocated-funds`;

export const apiGetAdminAccountUnallocatedFunds = async (
    accountId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetAdminAccountUnallocatedFundsResponse> => {
    const result = await httpGet<ApiGetAdminAccountUnallocatedFundsResponse>(
        window.APP_SETTINGS.paymentServiceApiBaseUri,
        API_GET_ADMIN_ACCOUNT_UNALLOCATED_FUNDS_URL(accountId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetAdminAccountUnallocatedFundsResponse = GetUnallocatedFundsResponse;

interface GetUnallocatedFundsResponse {
    total: number;
    inProgress: number;
    available: number;
}
