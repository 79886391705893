/**
 * Summary: Confirms Terms and Conditions and Privacy Policy for current user.
 *
 * Tags: PrivacyPolicyAndTermsAndConditions
 */
import { AxiosRequestConfig } from "axios";

import { httpPut } from "shared/api/apiHandler";

export const API_PUT_PRIVACY_POLICY_AND_TERMS_AND_CONDITIONS_CONFIRMED_URL =
    "api/v1/privacy-policy-and-terms-and-conditions/confirmed";

export const apiPutPrivacyPolicyAndTermsAndConditionsConfirmed = async (
    config?: AxiosRequestConfig
): Promise<ApiPutPrivacyPolicyAndTermsAndConditionsConfirmedResponse> => {
    const result = await httpPut<ApiPutPrivacyPolicyAndTermsAndConditionsConfirmedResponse>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_PUT_PRIVACY_POLICY_AND_TERMS_AND_CONDITIONS_CONFIRMED_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiPutPrivacyPolicyAndTermsAndConditionsConfirmedResponse = void;
