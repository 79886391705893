/**
 * Summary: Get schemes.
 *
 * Tags: Schemes
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_SCHEMES_URL = "api/v1/schemes";

export const apiGetSchemes = async (
    request?: ApiGetSchemesRequest,
    config?: AxiosRequestConfig
): Promise<ApiGetSchemesResponse> => {
    const result = await httpGet<ApiGetSchemesResponse, ApiGetSchemesRequest>(
        window.APP_SETTINGS.schemeManagementApiBaseUri,
        API_GET_SCHEMES_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export interface ApiGetSchemesRequest {
    regionId?: number; // Region Id. Example: 2
}

/**
 * Description: Success
 */
export type ApiGetSchemesResponse = GetSchemesLookupResponse;

interface GetSchemesLookupSchemeDto {
    id: number; // Scheme ID. Example: 2
    name?: string; // Scheme name. Example: Custodial EW
    schemeTypeId: number; // Scheme type ID. Example: 1
    schemeTypeName?: string; // Scheme type name. Example: scheme-types.custodial
    schemeVersions?: number[]; // List of scheme versions. Example: 1,2,3
    regionId?: number; // Region ID. Example: 2
    regionName?: string; // Region name. Example: scheme-regions.england-and-wales
    termsAndConditionsLink?: string; // Terms and conditions link. Example: https://www.omehq.com/terms-and-conditions
}

interface GetSchemesLookupResponse {
    schemes?: GetSchemesLookupSchemeDto[];
}
