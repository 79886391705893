import RouteValue from "shared/routes/models/routesValue";

// Permissions
export enum CompanyUserPermissions {
    ViewCompanyDetails = 1,
    EditCompanyDetails = 3,
    CreateCompanyOffices = 12,
    ViewUsersStaff = 21,
    EditUsersStaff = 23,
    MoveCompanyUsersToAnotherOffice = 24,
    DeactivateCompanyUserAccounts = 25,
    ManageInvitationsUserStaff = 27,
    ViewCompanyRoles = 31,
    AssignCompanyRoles = 32,
    ManageCompanyRoles = 33,
    ViewActivity = 40,
    ViewProperties = 50,
    ManageProperties = 51,
    MoveCompanyProperties = 52,
    BulkUploadCompanyProperties = 53,
    ViewTenancies = 60,
    ManageTenancies = 61,
    ViewCompanyDeposits = 70,
    ManageCompanyDeposits = 71,
    TransferMoneyForCompanyDeposits = 72,
    ExportCompanyDeposits = 73,
    ManageCompanyOffices = 80,
    DeactivateCompanyOffices = 81,
    ViewCompanyCertificateCustomization = 90,
    ManageCompanyCertificateCustomization = 91,
    ViewCompanyUnallocatedFunds = 100,
    ManageCompanyUnallocatedFunds = 101,
    ProcessCompanyEndOfReplacementProtection = 110,
    ProcessCompanyEndOfCustodialProtection = 111,
    ViewCompanyBillingDetails = 120,
    ManageCompanyBillingDetails = 121,
    ViewCompanyPaymentHistory = 130,
}

export enum InternalUserPermissions {
    LinkAndMergeAccountUsers = 1108,
    ViewCustomerUsersUsers = 1100,
    EditCustomerUserUsers = 1102,
    AssignNewCustomerTypeUsers = 1103,
    CreateNewCustomerUserUsers = 1104,
    DeactivateActivateCustomerUserUsers = 1105,
    DeleteCustomerUserUsers = 1106,
    ViewCompaniesBusinesses = 1200,
    EditCompanyDetailsBusinesses = 1202,
    DeactivateOffices = 1203,
    UpdateTermsAndConditionsPortalSettings = 1301,
    ViewPortalSettings = 1390,
    ViewInternalUsersAdminUsers = 1400,
    ViewSelectedInternalUserAdminUsers = 1401,
    InviteInternalUsersAdminUsers = 1402,
    DeleteInvitedInternalUserAdminUsers = 1403,
    EditInternalUserAdminUsers = 1404,
    DeleteInternalUserAdminUsers = 1405,
    DeactivateActivateInternalUserUsers = 1406,
    ViewRolesRolesAndPermissions = 1500,
    ManageRolesRolesAndPermissions = 1501,
    AssignRolesRolesAndPermissions = 1502,
    ViewActivity = 1600,
    EditTenantsInterestAmount = 1701,
    ViewProperties = 1800,
    ManageProperties = 1801,
    BulkUploadProperties = 1802,
    ViewTenancies = 1900,
    ManageTenancies = 1901,
    ViewDeposits = 2000,
    ManageDeposits = 2001,
    TransferMoneyForReplacementDeposits = 2002,
    TransferMoneyForCustodialDeposits = 2003,
    ExportDeposits = 2004,
    EditTenantsInterestStartDate = 2005,
    ViewCertificateCustomization = 2100,
    ManageCertificateCustomization = 2101,
    ProcessEndOfReplacementProtection = 2200,
    ProcessEndOfCustodialProtection = 2201,
    ViewBillingDetails = 2300,
    ManageBillingDetails = 2301,
    ProcessResolution = 2400,
    ApproveResolution = 2500,
    Assignment = 2600,
    ProcessComplaints = 2700,
    ViewNotes = 2800,
    ManageNotes = 2801,
    ViewUnallocatedFunds = 2900,
    ManageUnallocatedFunds = 2901,
    ViewPaymentHistory = 3000,
    CourtProcessResolution = 3100,
    ViewSchemeConfigurations = 3200,
    ManageSchemeConfigurations = 3201,
    ManageTenantInterestSchemeConfigurations = 3202,
}

export type RoutePermission = Pick<RouteValue, "isTolerantPermissions" | "permission">;

// Common groups of permissions
// Internal
export const viewCustomerUserInternalPermissions: RoutePermission = {
    isTolerantPermissions: true,
    permission: [
        InternalUserPermissions.ViewCustomerUsersUsers,
        InternalUserPermissions.EditCustomerUserUsers,
        InternalUserPermissions.DeactivateActivateCustomerUserUsers,
        InternalUserPermissions.CreateNewCustomerUserUsers,
        InternalUserPermissions.DeleteCustomerUserUsers,
    ],
};

export const viewInternalUserInternalPermissions: RoutePermission = {
    isTolerantPermissions: true,
    permission: [
        InternalUserPermissions.ViewSelectedInternalUserAdminUsers,
        InternalUserPermissions.EditInternalUserAdminUsers,
        InternalUserPermissions.DeactivateActivateInternalUserUsers,
        InternalUserPermissions.DeleteInternalUserAdminUsers,
    ],
};

export const viewCompanyDetailsInternalPermissions: RoutePermission = {
    isTolerantPermissions: true,
    permission: [
        InternalUserPermissions.ViewCompaniesBusinesses,
        InternalUserPermissions.EditCompanyDetailsBusinesses,
        InternalUserPermissions.DeactivateOffices,
    ],
};

// Company
export const managePropertiesPermissions: RoutePermission = {
    isTolerantPermissions: true,
    permission: [InternalUserPermissions.ManageProperties, CompanyUserPermissions.ManageProperties],
};

export const viewDepositsPermissions: RoutePermission = {
    isTolerantPermissions: true,
    permission: [
        CompanyUserPermissions.ViewCompanyDeposits,
        CompanyUserPermissions.ManageCompanyDeposits,
        CompanyUserPermissions.TransferMoneyForCompanyDeposits,
        CompanyUserPermissions.ViewCompanyUnallocatedFunds,
        CompanyUserPermissions.ManageCompanyUnallocatedFunds,
        InternalUserPermissions.ViewDeposits,
        InternalUserPermissions.ManageDeposits,
    ],
};
