import React from "react";

import { SvgIcon } from "@material-ui/core";
import { CheckCircleRounded, ErrorRounded, InfoRounded, WarningRounded } from "@material-ui/icons";
import clsx from "clsx";

import { getContainerWidthsBySize } from "shared/helpers";
import { NoteProps } from "shared/interfaces/container/note/note";
import { useNoteIconStyles, useNoteStyles } from "uiKit/container/note/Note.styles";
import NoteText from "uiKit/container/note/NoteText";

export const iconList = {
    info: InfoRounded,
    error: ErrorRounded,
    critical: ErrorRounded,
    warning: WarningRounded,
    success: CheckCircleRounded,
    transparent: InfoRounded,
};

const Note = ({
    variant,
    title,
    text,
    hideIcon,
    startIcon,
    children,
    titleVariant,
    iconVariant,
    titleClassName,
    size = "auto",
    className,
}: NoteProps): JSX.Element => {
    const styles = useNoteStyles(getContainerWidthsBySize(size));
    const iconStyles = useNoteIconStyles();

    return (
        <div className={clsx(styles.root, variant, className)}>
            <div className={styles.container}>
                {!hideIcon && title && (
                    <SvgIcon
                        component={startIcon || iconList[iconVariant ?? variant]}
                        classes={{ ...iconStyles }}
                        className={iconVariant ?? variant}
                        viewBox="0 0 22 22"
                    />
                )}
                <NoteText title={title} text={text} titleVariant={titleVariant} titleClassName={titleClassName} />
            </div>

            {children}
        </div>
    );
};

export default Note;
